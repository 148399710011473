import { useEffect, useState } from "react";
import Header from "./header";
import PreviewSection from "./previewSection";
import SetupSection from "./setUpSection";
import { dataService } from "../../../services/dataService";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function CreateLot({edit}){

    const [priceError,setPriceError]=useState(false);
    const [loading,setLoading]=useState(false);
    const Navigate=useNavigate();
    const { id,lotNumber ,uid}=useParams();
    const email = localStorage.getItem('user')
    const location=useLocation();
    const auctionData = location.state;
    const [isBanner, setIsBanner] = useState(true);
    const [lotData, setLotData] = useState(
        {
            auction_id: uid,
            seller_email : localStorage.getItem('user'),
            title1: "",
            title2: "",
            description: "",
            starting_price: "",
            low_estimate: "",
            high_estimate: "",
            shipping_details: "",
            current_bid: 0,
            tags: [],
            images: [],
        }
    )
    const [text,setText] = useState('');
    
    /**
     * The function fetchLots fetches data for a specific lot and sets the lot data in the state.
     */
    const fetchLots=async()=>{
        let endpoint = `?auction_id=${uid}&per_page=${lotNumber}`
        const response = await dataService.LotList(endpoint);
        const data=response.data.data;
        const lot=data.find((lot)=>lot.lot_number===parseInt(lotNumber))
        setLotData({
            auction_id: lot.auction_id || '',
            seller_email : lot.seller_email || '',
            title1: lot.title1 || '',
            title2: lot.title2 || '',
            description: lot.description || '',
            starting_price: lot.starting_price || '',
            low_estimate: lot.high_estimate!==0 ? lot.low_estimate : '',
            high_estimate: lot.high_estimate || '',
            shipping_details: lot.shipping_details || '',
            current_bid: 0,
            tags: lot.tags || [],
            images: lot.images || [],
        }) 
        setText(lot.description);
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(edit){
            fetchLots()
        }
        if(!auctionData){
            Navigate(`/auctions/${id}/${uid}/lots`)
        }
    },[])
    

    /**
     * The function `createLothandler` is an asynchronous function that creates a new lot by sending a
     * request to the server with the provided lot data, and updates the local storage with the new lot
     * count if the request is successful.
     */
    const createLothandler=async()=>{
        setLoading(true);
        const body={
            auction_id: auctionData.auction_id,
            seller_email : email,
            title1: lotData.title1,
            title2: lotData.title2,
            description: lotData.description,
            starting_price: lotData.starting_price=="" ? 0 : parseInt(lotData.starting_price),
            low_estimate: lotData.low_estimate=="" ? 0 : parseInt(lotData.low_estimate),
            high_estimate: lotData.high_estimate=="" ? 0 : parseInt(lotData.high_estimate),
            shipping_details: lotData.shipping_details,
            current_bid: 0,
            tags: lotData.tags,
            images: lotData.images,
        }
        const response = await dataService.CreateLot(body);
        setLoading(false);
        // let count = localStorage.getItem('lotCount')===undefined?0:localStorage.getItem('lotCount');
        // localStorage.setItem('lotCount', Number(count)+1)
        if(response.error===false){
            localStorage.setItem('lotSuccess','true')
            Navigate(`/auctions/${id}/${uid}/lots`)
        }
    }

    /**
     * The `editLotHandler` function is an asynchronous function that handles the editing of a lot by
     * sending a request to update the lot data and then navigating to the lots page of the auction.
     */
    const editLotHandler=async()=>{
        setLoading(true);
        const body={
            auction_id: uid,
            seller_email :localStorage.getItem('user'),
            title1: lotData.title1,
            title2: lotData.title2,
            description: lotData.description,
            starting_price: lotData.starting_price=="" ? 0 : parseInt(lotData.starting_price),
            low_estimate: lotData.low_estimate=="" ? 0 : parseInt(lotData.low_estimate),
            high_estimate: lotData.high_estimate=="" ? 0 : parseInt(lotData.high_estimate),
            shipping_details: lotData.shipping_details,
            tags: lotData.tags,
            images: lotData.images,
            lot_number: parseInt(lotNumber),
        }
        const response = await dataService.updateLot(body);
        setLoading(false);
        if(response.error===false){
            localStorage.setItem('lotUpdated','true')
            Navigate(`/auctions/${id}/${auctionData.auction_id}/lots`)
        }
    }


    return(
        auctionData && <div className="bg-[#F4F4F5]">
            {/* <!-- Navbar content --> */}
            <Header priceError={priceError}
                setPriceError={setPriceError}
                auctionData={auctionData}
                setLotData={setLotData}
                lotData={lotData} 
                loading={loading}
                isBanner={isBanner} 
                setIsBanner={setIsBanner}
                createLothandler={edit ? editLotHandler : createLothandler} />
            {/* <!-- main content --> */}
            <div className={`w-full flex ${isBanner ? '' : 'mt-[45px]'} `}>
                {/* <!-- preview section --> */}
                <PreviewSection lotData={lotData} auctionData={auctionData}/>
                {/* <!-- setup auction desktop view --> */}
                <div id='web-panel-configuration' 
                    className="xl:w-[26%] lg:w-[30%] md:w-[40%] md:block hidden  border-l border-[#D4D4D8] bg-[#fff]">
                    <SetupSection lotData={lotData} text={text} setText={setText}
                        auctionData={auctionData}
                        setLotData={setLotData}
                        priceError={priceError}
                        setPriceError={setPriceError} edit={edit} />
                </div>
                {/* <!-- modal content for faq view --> */}
                <div
                    data-te-modal-init
                    className="fixed left-0 top-0 z-[1055]  hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                    id="exampleModalXl2"
                    tabIndex="-1"
                    aria-labelledby="exampleModalXlLabel"
                    aria-modal="true"
                    role="dialog">
                    <div
                        data-te-modal-dialog-ref
                        className="pointer-events-none mx-5 relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] 
                            items-center opacity-0 transition-all duration-300 ease-in-out min-[740px]:mx-auto min-[740px]:mt-7 
                            min-[740px]:min-h-[calc(100%-3.5rem)] min-[740px]:max-w-[658px]">
                        <div
                            className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white 
                                bg-clip-padding text-current shadow-lg outline-none">
                            <div
                                className="flex flex-shrink-0 border-b border-[#A1A1A9] items-center justify-between r
                                ounded-t-md p-4">
                                <p className="text-[22px] text-[#343434] leading-normal font-medium pl-3">FAQs</p>
                                {/* <!--Close button--> */}
                                <button
                                    type="button"
                                    className="box-content rounded-none border-none"
                                    data-te-modal-dismiss
                                    aria-label="Close">
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img"/>
                                </button>
                            </div>
                            {/* <!--Modal body--> */}
                            <div className="relative p-4 mx-3 mt-2 ">
                                <div className=" sm:px-10" >
                                    <div className=" h-[330px] pr-6 overflow-y-auto overflow-x-hidden customScrollbar">
                                        <p className="text-[#343434] text-[12px] font-bold leading-normal">
                                            How do I register for an auction?</p>
                                        <p className="text-[#343434] text-[12px] font-medium leading-normal pt-3">
                                            Click on the &quot;Register for the Auction&quot; button on the auction page. 
                                            Once you confirm your intent to register for the auction, you&apos;ll be 
                                            provided with your paddle number which will also be emailed to you. 
                                            Once the auction is live, you can start bidding in the auction. 
                                            Please note that to validate your intent to bid for certain auctions, you might:</p>
                                        <ul className="text-[#343434] faq-list pl-5 text-[12px] font-medium 
                                        leading-normal pt-3 list-disc">
                                            <li>Be required to enter your credit or debit card information</li>
                                            <li>Be requested for documents such as a photo ID or proof of funds</li>
                                        </ul>
                                        <p className="text-[#343434] pt-3 text-[12px] font-bold leading-normal">
                                            What is a maximum bid?</p>
                                        <p className="text-[#343434] text-[12px] font-medium leading-normal pt-3">
                                            The maximum bid (or max bid) is the highest amount you are willing to 
                                            pay for an auction lot.
                                            When you set your max bid, the auction software automatically places bids 
                                            on your behalf at the next available increments if other competitive bids 
                                            come in to keep you as the highest bidder up to your maximum bid and never 
                                            any further.
                                            If no one else bids higher, the max bidder wins the auction at the lowest 
                                            possible price, which is the amount of the second-highest bid plus the 
                                            minimum increment.
                                            Max bids can be advantageous for bidders who can&apos;t constantly monitor 
                                            an auction or who want to avoid bidding wars. By placing a max bid, they 
                                            can set a limit on how much they&apos;re willing to pay and let the system 
                                            do the rest.</p>
                                    </div>
                                </div>
                                <div className="flex justify-center   shadow-bs6 pt-2 mb-4" 
                                    style={{ filter: "drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.06))" }}>
                                    <button 
                                        className="border border-[#A1A1A9] rounded-md text-[14px] text-[#52525A] 
                                            leading-normal mt-2.5 font-medium px-[20px] py-[12px] w-[135px]"
                                        data-te-toggle="modal"
                                        data-te-target="#exampleModalXl"
                                        data-te-ripple-init
                                        data-te-ripple-color="light"
                                        data-te-modal-dismiss
                                        aria-label="Close"
                                    >Add to FAQs</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
                {/* <!-- moda content for terms and conditions (edit part) --> */}
                <div
                    data-te-modal-init
                    className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                    id="exampleModalLg2"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLgLabel"
                    aria-modal="true"
                    role="dialog">
                    <div
                        data-te-modal-dialog-ref
                        className="pointer-events-none relative flex min-h-[calc(100%-1rem)] mx-5 w-auto translate-y-[-50px] 
                        items-center opacity-0 transition-all duration-300 ease-in-out min-[740px]:mx-auto min-[740px]:mt-7 
                        min-[740px]:min-h-[calc(100%-3.5rem)] min-[740px]:max-w-[658px]">
                        <div
                            className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white 
                            bg-clip-padding text-current shadow-lg outline-none">
                            <div
                                className="flex flex-shrink-0 border-b border-[#A1A1A9] items-center justify-between 
                                rounded-t-md p-4">
                                <p className="text-[22px] text-[#343434] leading-normal font-medium pl-3">Terms and conditions</p>
                                {/* <!--Close button--> */}
                                <button
                                    type="button"
                                    className="box-content rounded-none border-none"
                                    data-te-modal-dismiss
                                    aria-label="Close">
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img"/>
                                </button>
                            </div>
                            {/* <!--Modal body--> */}
                            <div className="relative p-4 sm:mx-12 mx-4">
                                <div className="border sm:my-10 my-3 pr-3 py-2 border-[#A1A1A9]">
                                    <p className="text-[14px]  p-4 h-[227px] overflow-y-auto overflow-x-hidden customScrollbar 
                                    text-[#343434] font-medium leading-normal">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec justo erat. 
                                        Morbi quis nulla in lacus feugiat finibus. Duis imperdiet eros sed felis rhoncus varius. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec justo erat. Morbi quis 
                                        nulla in lacus feugiat finibus. Duis imperdiet eros sed felis rhoncus varius. <br/> <br/>

                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec justo erat. 
                                        Morbi quis nulla in lacus feugiat finibus. Duis imperdiet eros sed felis rhoncus varius. 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec justo erat. Morbi quis 
                                        nulla in lacus feugiat finibus. Duis imperdiet eros sed felis rhoncus varius.</p>
                                </div>
                                <div className="flex drop-shadow-md justify-center   mt-2 mb-4">
                                    <button className="bg-[#282828] rounded-md text-[14px] text-[#fff] leading-normal mt-2.5 
                                    font-medium px-[20px] py-[12px] w-[135px]">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- modal for save draft --> */}
                <div
                    data-te-modal-init
                    className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                    id="exampleModalXl3"
                    tabIndex="-1"
                    aria-labelledby="exampleModalXlLabel"
                    aria-modal="true"
                    role="dialog">
                    <div
                        data-te-modal-dialog-ref
                        className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] 
                        items-center mx-3 opacity-0 transition-all duration-300 ease-in-out min-[640px]:mx-auto 
                        min-[640px]:mt-7 min-[640px]:min-h-[calc(100%-3.5rem)] min-[640px]:max-w-[658px]">
                        <div
                            className="pointer-events-auto relative flex w-full flex-col rounded-md border-none 
                            bg-white bg-clip-padding text-current shadow-lg outline-none">
                            <div
                                className="flex flex-shrink-0 items-center justify-end rounded-t-md p-3">
                                {/* <!--Close button--> */}
                                <button
                                    type="button"
                                    className="box-content rounded-none border-none"
                                    data-te-modal-dismiss
                                    aria-label="Close">
                                    <img src="/images/CloseButton-modalcontent.svg" alt="img"/>
                                </button>
                            </div>
                            {/* <!--Modal body--> */}
                            <div className="relative">
                                <p className=" px-6 text-center pb-4 leading-normal text-[22px] text-[#343434] font-medium">
                                    Save to your drafts?</p>
                                <p className=" px-[50px] text-center pb-4 leading-normal text-[16px] text-[#A1A1A9] font-medium">
                                    Once saved, you can upload auction lots, make design edits, and publish the auction whenever 
                                    you are ready to view it.</p>
                                <div className="flex justify-center pb-[47px]">
                                    <button 
                                        className="px-[31px] py-[12px] border esm:w-[135px] w-[100px] border-[#A1A1A9] 
                                        bg-[#fffff] text-[#282828] leading-normal font-medium text-[14px] rounded-[6px]" 
                                        data-te-modal-dismiss
                                        aria-label="Close">
                                        Cancel
                                    </button>
                                    <a href="Lot-listing-page.html" 
                                        className="ml-3 export-btn">
                                        <button 
                                            className=" px-[31px] esm:w-[135px] w-[100px] py-[12px] bg-[#282828] 
                                            text-[#FFFFFF] leading-normal font-medium text-[14px] rounded-[6px] " 
                                            data-te-modal-dismiss
                                            aria-label="Close">
                                            Save
                                        </button></a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
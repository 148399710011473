/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react'
import AuctionHeader from '../auctionHeader'
import ClassicPreview from './classicPreview'
import SetupSection from '../commonLayout/setUpSection'
import SingleLotPreview from './singleLotPreview'
import MenuLinkModal from '../commonLayout/setUpSection/menuLinkModal'
import StandALonePreview from './standALonePreview'
import { generateInitialTermsAndCondition, initialFAQs, timeZoneMap } from '../../../../CommonComponent/staticValues'
import { dataService } from '../../../../services/dataService'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Storage } from 'aws-amplify'
import uuid from 'react-uuid';
import { useOnClickOutside } from '../../../../CommonComponent/outsideClick'

export default function ClassicTemplate() {
    const {id} = useParams();
    const state = useLocation();
    const [editedAuctionData, setEditedAuctionData]=useState({});
    const outsideClickRef = useRef();
    const [isBanner, setIsBanner] = useState(true);

    useEffect(()=>{
        let originalDescriptionValue = "";
        if(state?.state){
            originalDescriptionValue = state.state.description;
        }
        const editedAuctionDataLength = Object.keys(editedAuctionData).length;
        if(editedAuctionDataLength> 0 && editedAuctionData.description &&editedAuctionData.description !== originalDescriptionValue){
            // console.log(Object.keys(editedAuctionData).length, editedAuctionData.description)
        }
    },[editedAuctionData]);
    let [auctionData, setAuctionData] = useState(
        {
            template_name: localStorage.getItem('selectedTemplateId')==1?'Classic':(localStorage.getItem('selectedTemplateId')==2?'Single Lot': 'Standalone'),
            menu_links: [],
            logo_image: "",
            logo_redirection_url: "",
            title: "",
            auction_image: "",
            description: "",
            currency: "USD",
            start_date: null,
            end_date: null,
            extension_type: localStorage.getItem('selectedTemplateId')==2 ? "Individual Lots" : "All Lots",
            extension_time: "",
            extension_time_between_lots: "",
            registration_type: "Email only",
            add_buyer_fees: "No additional fees",
            percentage: '',
            fees: '',
            faq:initialFAQs,
            time_zone: '',
            terms_and_condition: generateInitialTermsAndCondition(),
            publish_auction_results: false,
            show_bidder_location_in_bidder_history: false,
            show_bidding_history: false,
            make_your_auction_private: false,
            hide_auction_lots:false,
            passcode: "",
            font: {
                hearder_font: "",
                body_font: ""
            },
            buttons: {
                background_color: "",
                text_color: ""
            },
            header: {
                background_color: "",
                text_color: ""
            },
            content_area: {
                background_color: "",
                text_color: ""
            },
            footer: {
                background_color: "",
                text_color: ""
            },
            paddle: {
                background_color: "",
                text_color: ""
            }
        }
    )
    const [saveDraftModal,setSaveDraftModal] = useState(false)
    const [errorMessages, setErrorMessages] = useState({
        logoError: '',
        logoRedirectionUrlError: '',
        auction_image_error: ''
    })
    const [newMenuLink, setNewMenuLink] = useState({
        title: '',
        link_url: '',
        dropdown: [{ title: '', link_url: ''}]
    });
    const [menuLinkError, setMenuLinkError] = useState({
        title: false,
        url: false,
    });
    const [isAddingDropdown, setIsAddingDropdown] = useState(false);
    const [menuModal, setMenuModal] = useState(false)
    const [dropdownLinkError, setDropdownLinkError] = useState([]);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [startHour,setStartHour] = useState('')
    const [startMin,setStartMin] = useState('')
    const [endHour,setEndHour] = useState('')
    const [endMin,setEndMin] = useState('')
    const selectedId = localStorage.getItem('selectedTemplateId')
    const [loader, setLoader] = useState(false)
    const Navigate = useNavigate();
    const [logoLoader,setLogoLoader] = useState(false)
    const [imageLoader,setImageLoader] = useState(false)
    const [invalidDate, setInvalidDate]=useState(false);
    const [sameTime, setSameTime]=useState(false);
    const [text, setText]=useState("");
    const [mobileModal, isMobileModal] = useState(false);
    const [editMenu, setEditMenu] = useState({state: false, index: ''})

    const handleDropdownClose=()=>{
        setMenuModal(false);
        document.body.classList.remove('modal-open');
        isMobileModal(false);
        setMenuLinkError({
            title: false,
            url: false,
        });
        setNewMenuLink({
            title: '',
            link_url: '',
            dropdown: [{ title: '', link_url: ''}]
        })
        setIsAddingDropdown(false);
    }

    useOnClickOutside(outsideClickRef, () => {
        handleDropdownClose()
    });

    const convertTimestampToDayMonthYear = (timestamp) => {
        if(timestamp == 0 || timestamp == null){
            return "";
        }
        const date = new Date(Number(timestamp)); // Multiply by 1000 for milliseconds
    
        // Convert to the specified timezone
        const options = { timeZone: timeZoneMap[auctionData.time_zone !=="" ? auctionData.time_zone : 'Etc/GMT'], day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleString('en-US', options);
    
        return formattedDate;
    };
    
    useEffect(()=>{
        if(state.state!==null){
            setAuctionData(
                {
                    auction_id: state.state.auction_id,
                    template_name: localStorage.getItem('selectedTemplateId')==1?'Classic':(localStorage.getItem('selectedTemplateId')==2?'Single lot': 'Standalone'),
                    menu_links: state.state.menu_links,
                    logo_image: state.state.logo_image,
                    logo_redirection_url: state.state.logo_redirection_url,
                    title: state.state.title,
                    auction_image: state.state.auction_image,
                    description: state.state.description,
                    currency: state.state.currency,
                    start_date: convertTimestampToDayMonthYear(state.state.start_date),
                    end_date: convertTimestampToDayMonthYear(state.state.end_date),
                    extension_type: state.state.extension_type,
                    extension_time: state.state.extension_time,
                    extension_time_between_lots: state.state.extension_time_between_lots,
                    registration_type: state.state.registration_type,
                    add_buyer_fees: state.state.add_buyer_fees,
                    percentage: state.state.percentage,
                    hide_auction_lots: state.state.hide_auction_lots,
                    fees: state.state.fees,
                    faq:state.state.faq,
                    status: state.state.status,
                    time_zone: state.state.time_zone,
                    terms_and_condition: state.state.terms_and_condition,
                    publish_auction_results: state.state.publish_auction_results,
                    show_bidder_location_in_bidder_history: state.state.show_bidder_location_in_bidder_history,
                    show_bidding_history: state.state.show_bidding_history,
                    make_your_auction_private: state.state.make_your_auction_private,
                    passcode: state.state.passcode,
                    font: {
                        hearder_font: state.state.font.hearder_font,
                        body_font: state.state.font.body_font
                    },
                    buttons: {
                        background_color: state.state.buttons.background_color,
                        text_color: state.state.buttons.text_color
                    },
                    header: {
                        background_color: state.state.header.background_color,
                        text_color: state.state.header.text_color
                    },
                    content_area: {
                        background_color: state.state.content_area.background_color,
                        text_color: state.state.content_area.text_color
                    },
                    footer: {
                        background_color: state.state.footer.background_color,
                        text_color: state.state.footer.text_color
                    },
                    paddle: {
                        background_color: state.state.paddle.background_color,
                        text_color: state.state.paddle.text_color
                    }
                }
            )
            const startTimestamp = state.state.start_date;
            const endTimestamp = state.state.end_date;
            const timezone = state.state.timezone;

            // Function to convert timestamp to date in a particular timezone
            const getDateInTimezone = (timestamp, timezone) => {
                return new Date(timestamp).toLocaleString('en-US', { timeZone: timezone });
            };

            // Get date, hour, and minute of start date in the specified timezone
            const startDate = startTimestamp ? new Date(getDateInTimezone(startTimestamp, timeZoneMap[state.state.time_zone!=="" ? state.state.time_zone : 'Etc/GMT'])) : null;
            const startHour = startDate ? String(startDate.getHours()).padStart(2, '0') : '';
            const startMin = startDate ? String(startDate.getMinutes()).padStart(2, '0') : '';

            // Get date, hour, and minute of end date in the specified timezone
            const endDate = endTimestamp ? new Date(getDateInTimezone(endTimestamp, timeZoneMap[state.state.time_zone!=="" ? state.state.time_zone : 'Etc/GMT'])) : null;
            const endHour = endDate ? String(endDate.getHours()).padStart(2, '0') : '';
            const endMin = endDate ? String(endDate.getMinutes()).padStart(2, '0') : '';

            // Now you can use startHour, startMin, endHour, endMin as needed
            setStartDate(state.state.start_date ? startDate : "");
            setStartHour(startHour);
            setStartMin(startMin);
            setEndDate(state.state.end_date ? endDate: "");
            setEndHour(endHour);
            setEndMin(endMin);
            setText(state.state?.description);
        }
    },[])
  
    /**
     * The function `handleLogoChange` is used to handle the change event when a logo file is selected,
     * and it updates the state with the selected logo image and displays an error message if the file
     * size exceeds 5MB.
     */
    const handleLogoChange = async(event,image_type) => {
        if(image_type==='logo'){
            setLogoLoader(true)
        }
        else{
            setImageLoader(true)
        }
        const selectedLogo = event.target.files[0];
        const validLogoTypes = ['image/jpeg', 'image/png', "image/jpg"];
        const validImageTypes = ['image/jpeg', 'image/png', "image/jpg", 'image/gif'];
        if(image_type==='logo'){
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                logoError: ""
            }));
        }else{
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                auction_image_error: ""
            }));
        }
        if (image_type === 'logo') {
            if (!validLogoTypes.includes(selectedLogo?.type)) {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    logoError: "Please select a logo file in JPG or PNG format."
                }));
                setLogoLoader(false);
                return;
            }
        } else {
            if (!validImageTypes.includes(selectedLogo?.type)) {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    auction_image_error: "Please select a lead image in JPG, PNG or GIF format."
                }));
                setImageLoader(false);
                return;
            }
        }

        const upload = await Storage.put(`DomainName/Auctions/images/${uuid()}/${selectedLogo.name}`, selectedLogo,{
            contentType: selectedLogo.type,
            acl: "public-read",
        })
        
        if (selectedLogo && selectedLogo.size <= 5 * 1024 * 1024) {
            if(image_type==='logo'){
                setAuctionData(prevData => ({
                    ...prevData,
                    logo_image: upload.key
                }));
                setEditedAuctionData((prevData) => ({
                    ...prevData,
                    logo_image: upload.key
                }));
                setTimeout(() => {
                    setLogoLoader(false)
                    setImageLoader(false);
                }, 500);
            }
            else{
                setAuctionData(prevData => ({
                    ...prevData,
                    auction_image: upload.key
                }));
                setEditedAuctionData((prevData) => ({
                    ...prevData,
                    auction_image: upload.key
                }));
                setTimeout(() => {
                    setLogoLoader(false)
                    setImageLoader(false);
                }, 500);
            }
        } else {
            if(image_type==='logo'){
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    logoError: "Please select a logo file smaller than 5MB."
                }));
                setLogoLoader(false)
            }else{
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    auction_image_error: "Please select a lead image smaller than 5MB."
                }));
                setImageLoader(false)
            }
        }
    };
    /**
     * The function `handleLogoDelete` is used to delete either the logo image and redirection URL or
     * the auction image, depending on the value of the `image_type` parameter.
     */
    const handleLogoDelete = (image_type) => {
        if(image_type==='logo'){
            setAuctionData(prevData => ({
                ...prevData,
                logo_image: "",
                logo_redirection_url: ""
            }));
            setEditedAuctionData((prevData) => ({
                ...prevData,
                logo_image: "",
                logo_redirection_url: ""
            }));
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                logoError: ""
            }));
            setLogoLoader(false)
        }else{
            setAuctionData(prevData => ({
                ...prevData,
                auction_image: "",
            }));
            setEditedAuctionData((prevData) => ({
                ...prevData,
                auction_image: ""
            }));
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                auction_image_error: ""
            }));
            setImageLoader(false)
        }
    };
    /**
     * The function `handleLogoRedirectionChange` validates a URL entered by the user and updates the
     * state accordingly.
     */
    const handleLogoRedirectionChange = (event) => {
        event.preventDefault();
        const newRedirectionUrl = event.target.value;
        //const regex = '((http|https)://|(www.))?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';
        const regex= '^(https?://|www\.)[a-zA-Z0-9@:%._\\+-~#?&//=]{2,256}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        const urlPattern = new RegExp(regex);
        if (urlPattern.test(newRedirectionUrl) || newRedirectionUrl==='') {
            setAuctionData(prevData => ({
                ...prevData,
                logo_redirection_url: newRedirectionUrl
            }));
            setEditedAuctionData((prevData) => ({
                ...prevData,
                logo_redirection_url: newRedirectionUrl
            }));
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                logoRedirectionUrlError: ""
            }));
        } else {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                logoRedirectionUrlError: "Enter a valid URL"
            }));
            setAuctionData(prevData => ({
                ...prevData,
                logo_redirection_url: newRedirectionUrl
            }));
            setEditedAuctionData((prevData) => ({
                ...prevData,
                logo_redirection_url: newRedirectionUrl
            }));
        }
    };
    /**
     * The function `addMenuLink` adds a new menu link to the `auctionData` state if the number of menu
     * links is less than 7.
     */


    const addMenuLink = () => {
        if (auctionData.menu_links.length < 7) {
            if(editMenu.state){
                setAuctionData((prevState) => ({
                    ...prevState,
                    menu_links: prevState.menu_links.map((link, index) =>
                        index === editMenu.index ? { ...link, ...newMenuLink } : link
                    ),
                }));
            }else{
                setAuctionData((prevState) => ({
                    ...prevState,
                    menu_links: [...prevState.menu_links, newMenuLink],
                }));
            }
            setNewMenuLink({
                title: '',
                link_url: '',
                dropdown: [{
                    title: '',
                    link_url: '',
                }],
            });
            setIsAddingDropdown(false)
            setMenuModal(false)
            document.body.classList.remove('modal-open')
        }
    };
    /**
     * The deleteMenuLink function is used to remove a menu link from the auction data by filtering out
     * the link at the specified index.
     */
    const deleteMenuLink = (index) => {
        setAuctionData((prevState) => ({
            ...prevState,
            menu_links: prevState.menu_links.filter((_, i) => i !== index),
        }));
    };
    /**
     * The function `addDropdownLink` adds a new dropdown link to the `newMenuLink` state if the number
     * of existing dropdown links is less than 5.
     */
    const addDropdownLink = () => {
        if (newMenuLink.dropdown.length < 5) {
            setNewMenuLink((prevState) => ({
                ...prevState,
                dropdown: [
                    ...prevState.dropdown,
                    {
                        title: '',
                        link_url: '',
                    },
                ],
            }));
        }
    };

    
    /**
     * The function `handleCreateAuction` is an asynchronous function that handles the creation of an
     * auction by setting loader state, making an API call to create the auction, and resetting the
     * auction data and other states if the API call is successful.
     */
    const handleCreateAuction = async(e)=>{
        e.preventDefault();
        setLoader(true)
        let data;

        if(state.state===null){

            // let start = `${startDate} ${startHour==''?0:startHour}:${startMin==''?0:startMin}`;
            // setAuctionData((prevData)=>({...prevData, start_date: new Date(start).getTime() / 1000}))
            // let end = `${endDate} ${endHour==''?0:endHour}:${endMin==''?0:endMin}`;
            // setAuctionData((prevData)=>({...prevData, end_date: new Date(end).getTime() / 1000})) 
            if(auctionData.extension_time==""){
                auctionData={...auctionData, extension_time: 2}
            }
            data = await dataService.CreateAuction(auctionData);
            if(data.error==false){
                setAuctionData({
                    template_name: "",
                    menu_links: [],
                    logo_image: "",
                    logo_redirection_url: "",
                    title: "",
                    auction_image: "",
                    description: "",
                    currency: "USD",
                    start_date: null,
                    end_date: null,
                    extension_type: "All Lots",
                    extension_time: "",
                    extension_time_between_lots: "",
                    registration_type: "Email only",
                    add_buyer_fees: "No additional fees",
                    percentage: '',
                    fees: '',
                    faq:initialFAQs,
                    status: "",
                    time_zone: "",
                    terms_and_condition: generateInitialTermsAndCondition(),
                    publish_auction_results: false,
                    show_bidder_location_in_bidder_history: false,
                    show_bidding_history: false,
                    make_your_auction_private: false,
                    passcode: "",
                    font: {
                        hearder_font: "",
                        body_font: ""
                    },
                    buttons: {
                        background_color: "",
                        text_color: ""
                    },
                    header: {
                        background_color: "",
                        text_color: ""
                    },
                    content_area: {
                        background_color: "",
                        text_color: ""
                    },
                    footer: {
                        background_color: "",
                        text_color: ""
                    },
                    paddle: {
                        background_color: "",
                        text_color: ""
                    }
                })
                setSaveDraftModal(false)
                setLoader(false)
                if(state.state!==null){
                    if(state.state.status=="Draft"){
                        localStorage.setItem('AuctionEditSuccess', 'true')
                    }else{
                        localStorage.setItem('AuctionLiveSuccess', 'true')
                    }
                }
                else{
                    localStorage.setItem('AuctionSuccess', 'true')
                }
                if(data!==undefined && data.data.auctions_id!==undefined){
                    Navigate(`/auctions/${data.data._id}/${data.data.auctions_id}/overview`, {state: auctionData})
                }
                else{
                    Navigate(`/auctions/${auctionData._id}/${auctionData.auction_id}/overview`, {state: auctionData})
                }
            }

        }
        else{
            const updatedData={...editedAuctionData, menu_links : auctionData.menu_links, faq : auctionData.faq}
            setTimeout(async() => {
                data = await dataService.editAuction(auctionData.auction_id,updatedData)
                if(data.error==false){
                    setAuctionData({
                        template_name: "",
                        menu_links: [],
                        logo_image: "",
                        logo_redirection_url: "",
                        title: "",
                        auction_image: "",
                        description: "",
                        currency: "USD",
                        start_date: null,
                        end_date: null,
                        extension_type: "All Lots",
                        extension_time: "",
                        extension_time_between_lots: "",
                        registration_type: "Email only",
                        add_buyer_fees: "No additional fees",
                        percentage: '',
                        fees: '',
                        faq:initialFAQs,
                        status: "",
                        time_zone: "",
                        terms_and_condition: generateInitialTermsAndCondition(),
                        publish_auction_results: false,
                        show_bidder_location_in_bidder_history: false,
                        show_bidding_history: false,
                        make_your_auction_private: false,
                        passcode: "",
                        font: {
                            hearder_font: "",
                            body_font: ""
                        },
                        buttons: {
                            background_color: "",
                            text_color: ""
                        },
                        header: {
                            background_color: "",
                            text_color: ""
                        },
                        content_area: {
                            background_color: "",
                            text_color: ""
                        },
                        footer: {
                            background_color: "",
                            text_color: ""
                        },
                        paddle: {
                            background_color: "",
                            text_color: ""
                        }
                    })
                    setSaveDraftModal(false)
                    setLoader(false)
                    if(state.state!==null){
                        if(state.state.status=="Draft"){
                            localStorage.setItem('AuctionEditSuccess', 'true')
                        }else{
                            localStorage.setItem('AuctionLiveSuccess', 'true')
                        }
                    }
                    else{
                        localStorage.setItem('AuctionSuccess', 'true')
                    }
                    if(data!==undefined && data.data.auctions_id!==undefined){
                        Navigate(`/auctions/${id}/${data.data.auctions_id}/overview`, {state: auctionData})
                    }
                    else{
                        Navigate(`/auctions/${id}/${auctionData.auction_id}/overview`, {state: auctionData})
                    }
                }
            }, 500);
        }

    }
    /**
     * The function `deleteDropdownLink` is used to remove a dropdown link from a menu and update the
     * state accordingly.
     */
    const deleteDropdownLink = (dropdownIndex) => {
        setNewMenuLink((prevState) => {
            const updatedLinks = prevState.dropdown.slice();
            if (Array.isArray(updatedLinks)) {
                if (dropdownIndex >= 0 && dropdownIndex < updatedLinks.length) {
                    updatedLinks.splice(dropdownIndex, 1);
                }
            }
            if(updatedLinks.length===0){
                setIsAddingDropdown(false)
                return {
                    ...prevState,
                    dropdown: [{ title: '', link_url: ''}]
                };
            }
            // setDropdownLinkError([])
            return {
                ...prevState,
                dropdown: updatedLinks, 
            };
        });
    };

    return (
        <div className="bg-[#F4F4F5]" >
            <AuctionHeader auctionData={auctionData} setAuctionData={setAuctionData}
                setEditedAuctionData={setEditedAuctionData}
                handleLogoChange={handleLogoChange}
                handleLogoRedirectionChange={handleLogoRedirectionChange} handleLogoDelete={handleLogoDelete}
                errorMessages={errorMessages}
                invalidDate={invalidDate}
                sameTime={sameTime}
                deleteMenuLink={deleteMenuLink}
                addDropdownLink={addDropdownLink}
                deleteDropdownLink={deleteDropdownLink}
                setNewMenuLink={setNewMenuLink}
                isAddingDropdown={isAddingDropdown} 
                setIsAddingDropdown={setIsAddingDropdown}
                setMenuModal={setMenuModal}
                startDate={startDate} 
                setStartDate={setStartDate}
                endDate={endDate} 
                setEndDate={setEndDate}
                startHour={startHour}
                setStartHour={setStartHour}
                startMin={startMin}
                setStartMin={setStartMin}
                endHour={endHour}
                setEndHour={setEndHour}
                endMin={endMin}
                setEndMin={setEndMin}
                handleCreateAuction={handleCreateAuction}
                saveDraftModal={saveDraftModal}
                setSaveDraftModal={setSaveDraftModal}
                loader={loader}
                menuLinkError={menuLinkError} 
                logoLoader={logoLoader}
                setLogoLoader={setLogoLoader}
                imageLoader={imageLoader}
                setImageLoader={setImageLoader}
                setSameTime={setSameTime}
                setInvalidDate={setInvalidDate}
                mobileModal={mobileModal}
                isMobileModal={isMobileModal}
                setEditMenu={setEditMenu}
                isBanner={isBanner} setIsBanner={setIsBanner}
            />
            {selectedId!==undefined &&
            <div className={`w-full flex ${isBanner ? '' : 'mt-[45px]' } `}>
                {selectedId==1 ?
                    <ClassicPreview auctionData={auctionData}
                        startDate={startDate} 
                        setStartDate={setStartDate}
                        endDate={endDate} 
                        setEndDate={setEndDate}
                        startHour={startHour}
                        setStartHour={setStartHour}
                        startMin={startMin}
                        setStartMin={setStartMin}
                        endHour={endHour}
                        setEndHour={setEndHour}
                        endMin={endMin}
                        setEndMin={setEndMin}
                    />
                    : (selectedId==2?
                        <SingleLotPreview auctionData={auctionData}
                            startDate={startDate} 
                            setStartDate={setStartDate}
                            endDate={endDate} 
                            setEndDate={setEndDate}
                            startHour={startHour}
                            setStartHour={setStartHour}
                            startMin={startMin}
                            setStartMin={setStartMin}
                            endHour={endHour}
                            setEndHour={setEndHour}
                            endMin={endMin}
                            setEndMin={setEndMin}
                        />:
                        <StandALonePreview auctionData={auctionData}
                            startDate={startDate} 
                            setStartDate={setStartDate}
                            endDate={endDate} 
                            setEndDate={setEndDate}
                            startHour={startHour}
                            setStartHour={setStartHour}
                            startMin={startMin}
                            setStartMin={setStartMin}
                            endHour={endHour}
                            setEndHour={setEndHour}
                            endMin={endMin}
                            setEndMin={setEndMin}
                        />)
                }
                <div id='web-panel-configuration' className="xl:w-[26%] lg:w-[30%] md:w-[40%] md:block hidden  border-l border-[#D4D4D8] bg-[#fff]">
                  
                    <SetupSection auctionData={auctionData} setAuctionData={setAuctionData}
                        setEditedAuctionData={setEditedAuctionData}
                        setText={setText}
                        text={text}
                        handleLogoChange={handleLogoChange}
                        invalidDate={invalidDate}
                        setInvalidDate={setInvalidDate}
                        sameTime={sameTime}
                        setSameTime={setSameTime}
                        handleLogoRedirectionChange={handleLogoRedirectionChange}
                        handleLogoDelete={handleLogoDelete}
                        errorMessages={errorMessages}
                        deleteMenuLink={deleteMenuLink}
                        setNewMenuLink={setNewMenuLink}
                        setMenuModal={setMenuModal}
                        startDate={startDate} 
                        setStartDate={setStartDate}
                        endDate={endDate} 
                        setEndDate={setEndDate}
                        startHour={startHour}
                        setStartHour={setStartHour}
                        startMin={startMin}
                        setStartMin={setStartMin}
                        endHour={endHour}
                        setEndHour={setEndHour}
                        endMin={endMin}
                        setEndMin={setEndMin}
                        logoLoader={logoLoader}
                        setLogoLoader={setLogoLoader}
                        imageLoader={imageLoader}
                        setImageLoader={setImageLoader}
                        isMobileModal ={isMobileModal}
                        setEditMenu={setEditMenu} />
                </div>
            </div>}
            <MenuLinkModal 
                setMenuModal={setMenuModal} 
                menuModal={menuModal} 
                outsideClickRef={outsideClickRef}
                newMenuLink={newMenuLink} 
                setNewMenuLink={setNewMenuLink} 
                addMenuLink={addMenuLink} 
                isAddingDropdown={isAddingDropdown} 
                setIsAddingDropdown={setIsAddingDropdown} 
                addDropdownLink={addDropdownLink}
                deleteDropdownLink={deleteDropdownLink}
                dropdownLinkError={dropdownLinkError} 
                setDropdownLinkError={setDropdownLinkError}
                menuLinkError={menuLinkError} 
                setMenuLinkError={setMenuLinkError}
                auctionData={auctionData}
                mobileModal = {mobileModal}
                isMobileModal={isMobileModal}
                editMenu={editMenu}
                handleDropdownClose={handleDropdownClose}
            />
        </div>
    )
}